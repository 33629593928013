<template>
  <div class="lcf-bg-wrapper">
    <div v-if="loggedIn" class="lcf-account-panel">
      <NavBar>
        <template #left>
          <Icon
            name="user-circle-o"
            class="personal-icon"
            @click="$router.push({ name: 'Personal' })"
          ></Icon>
        </template>
      </NavBar>
      <div class="lcf-user">
        <div class="lcf-user-head">
          <div class="lcf-account-total-title">
            <div>净本金（元）</div>
            <Icon name="question-o" class="lcf-icon" @click="alert"></Icon>
          </div>
          <div class="lcf-account-total-number">
            <div>{{ eyeOpen ? account.totalNetAssets : '****' }}</div>
            <Icon
              :name="eyeOpen ? 'eye-o' : 'closed-eye'"
              class="lcf-icon"
              @click="eyeOpen = !eyeOpen"
            ></Icon>
          </div>
        </div>
        <div class="lcf-user-body">
          <div class="lcf-account-balance">
            <div>可用余额（元）</div>
            <div>{{ eyeOpen ? account.balance : '****' }}</div>
          </div>
          <div class="lcf-account-netcapital">
            <div>资产总额（元）</div>
            <div>{{ eyeOpen ? account.total : '****' }}</div>
          </div>
          <div class="lcf-account-paidinterest">
            <div>累计收益（元）</div>
            <div>{{ eyeOpen ? account.paidInterest : '****' }}</div>
          </div>
        </div>
        <div class="lcf-user-foot">
          <Button type="primary" round block @click="withdraw" v-waves>
            提现
          </Button>
        </div>
      </div>

      <Grid direction="horizontal" :column-num="2">
        <GridItem
          icon="photo-o"
          text="我的资产"
          @click="go('MyAssets', { status: 'current' })"
          v-waves
        >
          <template #icon>
            <div class="lcf-grid-icon-wdzc"></div>
          </template>
        </GridItem>
        <GridItem
          icon="photo-o"
          text="资产历史"
          @click="go('MyAssets', { status: 'history' })"
          v-waves
        >
          <template #icon>
            <div class="lcf-grid-icon-zcls"></div>
          </template>
        </GridItem>
        <GridItem
          class="no-bottom-hairline"
          icon="photo-o"
          text="账户流水"
          @click="go('AccountLogs')"
          v-waves
        >
          <template #icon>
            <div class="lcf-grid-icon-zhls"></div>
          </template>
        </GridItem>
        <GridItem
          class="no-bottom-hairline"
          icon="photo-o"
          text="投标记录"
          @click="go('AddinRecord')"
          v-waves
        >
          <template #icon>
            <div class="lcf-grid-icon-tbjl"></div>
          </template>
        </GridItem>
      </Grid>

      <div class="lcf-my-contact-us">
        <div @click="show = true">联系客服</div>
      </div>

      <ActionSheet
        v-model="show"
        :actions="actions"
        @select="onSelect"
        title="联系方式"
        cancel-text="取消"
        :closeable="false"
      >
      </ActionSheet>
    </div>
    <div v-else class="lcf-login-guidance">
      <Empty description="还没有登录, 赶快去登录吧~">
        <Button
          round
          block
          class="login-btn"
          type="primary"
          @click="login"
          v-waves
        >
          现在登录
        </Button>
      </Empty>
    </div>
  </div>
</template>

<script>
import {
  Notify,
  NavBar,
  Icon,
  Grid,
  GridItem,
  Button,
  Dialog,
  ActionSheet,
  Empty,
} from 'vant'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    NavBar,
    Icon,
    Grid,
    GridItem,
    Button,
    ActionSheet,
    Empty,
  },
  data() {
    return {
      account: {
        // 资产总额
        total: 0,
        // 待收本金
        waitCapital: 0,
        // 待收利息
        waitInterest: 0,
        // 累计收益
        paidInterest: 0,
        // 可用余额
        balance: 0,
        // 净本金
        totalNetAssets: 0,
        yesterdayInterest: 0,
      },

      eyeOpen: true,

      show: false,
      actions: [{ name: '电话联系：400-850-0700' }],
    }
  },
  created() {
    if (this.loggedIn) {
      this.showAccountInfo()
      this.getBankCardInfo()
    }
  },
  methods: {
    ...mapActions(['requestAccountInfo', 'requestBankCard']),
    go(routeName, params) {
      setTimeout(() => {
        this.$router.push({ name: routeName, params })
      }, 300)
    },
    onSelect() {
      this.show = false
      window.location.href = 'tel:400-850-0700'
    },
    alert() {
      Dialog.alert({
        title: '温馨提示',
        message: '净本金 = 充值金额 - 提现金额 - 权益兑换金额',
      })
    },
    login() {
      this.$router.push({ name: 'Signin', query: { to: this.$route.fullPath } })
    },
    async showAccountInfo() {
      let error, data
      ;[error, data] = await to(this.requestAccountInfo())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      } else {
        this.account.total = data.total
        this.account.waitCapital = data.wait_capital
        this.account.waitInterest = data.wait_interest
        this.account.paidInterest = data.paid_interest
        this.account.balance = data.balance
        this.account.totalNetAssets = data.total_net_assets
        this.account.yesterdayInterest = data.yesterday_interest
      }
    },
    async getBankCardInfo() {
      let error, data, ret
      ;[error, data] = await to(this.requestBankCard())

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }
      if (data?.account) {
        ret = data.account
      }

      return ret
    },

    async withdraw() {
      let error, account
      ;[error, account] = await to(this.getBankCardInfo())

      if (account) {
        this.go('LlpWithdraw')
      } else {
        Dialog.alert({
          title: '温馨提示',
          message: '当前账户未绑定银行卡，无法提现',
        })
      }
    },
  },
  computed: {
    ...mapGetters(['loggedIn']),
  },
}
</script>

<style scoped lang="scss">
.lcf-bg-wrapper {
  background: #fff;
  height: calc(100% - 50px);
}
.lcf-account-panel {
  background: $lcf-color-bg-gray-light;
  .van-nav-bar {
    background: #fff;
  }
  .van-nav-bar.van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  .personal-icon {
    font-size: 24px;
    color: #4f5257;
  }
  .lcf-user {
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 36px 0 0;
    height: 256px;
    margin: 0 0 8px;
    .lcf-user-head {
      display: flex;
      flex: 3 1;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;
      width: 40%;
      .lcf-account-total-title {
        display: flex;
        flex-flow: row nowrap;
        color: $lcf-color-text-gray;
        div:first-child {
          font-size: 12px;
        }
        .lcf-icon {
          font-size: 16px;
        }
      }
      .lcf-account-total-number {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        div:first-child {
          flex: 1 1;
          text-align: center;
        }
        div:first-child,
        .lcf-icon {
          padding: 0 8px;
          font-size: 24px;
          line-height: 48px;
        }
        .lcf-icon {
          justify-self: flex-end;
        }
      }
    }
    .lcf-user-body {
      display: flex;
      flex-flow: row nowrap;
      flex: 2 1;
      width: 100%;
      .lcf-account-balance {
        flex: 1 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        div:first-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
        div:last-child {
          font-size: 16px;
          line-height: 32px;
        }
      }
      .lcf-account-netcapital {
        flex: 1 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        div:first-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
        div:last-child {
          font-size: 16px;
          line-height: 32px;
        }
      }
      .lcf-account-paidinterest {
        flex: 1 1;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        div:first-child {
          font-size: 12px;
          color: $lcf-color-text-gray;
        }
        div:last-child {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
    .lcf-user-foot {
      flex: 3 1;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .lcf-grid-icon-wdzc,
  .lcf-grid-icon-zcls,
  .lcf-grid-icon-zhls,
  .lcf-grid-icon-tbjl {
    height: 64px;
    width: 32px;
  }
  .lcf-grid-icon-wdzc {
    background: content-box left / 16px auto no-repeat
      url('~@/assets/img/icon_mine_my_fund.png');
  }
  .lcf-grid-icon-zcls {
    background: content-box left / 16px auto no-repeat
      url('~@/assets/img/icon_project_detail_time.png');
  }
  .lcf-grid-icon-zhls {
    background: content-box left / 16px auto no-repeat
      url('~@/assets/img/icon_mine_account_flow.png');
  }
  .lcf-grid-icon-tbjl {
    background: content-box left / 16px auto no-repeat
      url('~@/assets/img/icon_mine_record.png');
  }
  ::v-deep .no-bottom-hairline {
    .van-hairline::after {
      border-bottom-width: 0;
    }
  }
  .lcf-my-contact-us {
    color: $lcf-color-default;
    background: #fff;
    padding: 16px;
    div {
      border: 1px solid $lcf-color-default;
      width: 64px;
      height: 16px;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
      margin: 0 auto;
      border-radius: 8px;
    }
  }
}
.lcf-login-guidance {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-btn {
    width: 132px;
  }
}
</style>
